



















































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import RegisterNumberForm from '@/components/event/RegisterNumberForm.vue';
import { EventRepository } from '@/repositories/EventRepository';
import { EventCmsRepository } from '@/repositories/EventCmsRepository';
import { SubscriptionPackageCmsRepository } from '@/repositories/SubscriptionPackageCmsRepository';
import { EventItem, EventOptionItem } from '@/models/EventItem';
import { SubscriptionPackageItem } from '@/models/SubscriptionPackageItem';
import { ReqPostEventEntry, EventOption } from '@/gen/api';
import { getSubscribedPackageIds } from '@/common/subscriptionUtils';
import utils from '@/common/utils';
import { INFO_SUBSCRIPTION_CLAIMKEY } from '@/common/constants';

@Component({
  components: {
    RegisterNumberForm: RegisterNumberForm
  }
})
export default class P0233 extends Vue {
  @Prop() propEventItem!: string;
  @Prop() propRequestBody!: string;
  // クエリ―イベントID
  queryEventId = '';
  // 申込可能ユーザであるか
  isAllowedEntry = false;
  // イベント申込料金区分
  eventFeeClassCode = '';
  // 無料イベント申込確認ダイアログ表示状態
  isRegisterFreeEventConfirmDialogOpening = false;
  // 申込期間中フラグ
  registerPeriodDuringFlg = true;
  // エラーメッセージ
  errorMsg = '';
  // インフォメーション
  infoMsg = '';
  // 申込ボタン非活性
  entryButtonDisabled = false;
  entryButtonLoading = false;
  // 選択肢・備考欄の非活性(イベント対象フラグfalse または全てのイベント内残席数が0 またはデータフェッチ時にエラーが発生)
  inputDisabled = false;
  // 初期化完了フラグ
  isInitialized = false;

  // イベント情報
  eventItem = {} as EventItem;
  // サブスクパッケージ情報
  subscriptionPackages = [] as SubscriptionPackageItem[];
  // リクエストボディ
  requestBody = {
    event_id: this.queryEventId,
    event_options: [],
    note: ''
  } as ReqPostEventEntry;

  $refs!: {
    RegisterNumberForm: RegisterNumberForm;
  };

  // LocalStorageへの保存内容
  readonly eventIdKey = 'event_id';
  readonly eventDetailKey = 'event_detail';
  // イベントリポジトリ
  get eventRepo() {
    return new EventRepository();
  }
  // イベントCMSリポジトリ
  get eventCmsRepository() {
    return new EventCmsRepository(
      this.$store.state.isSP,
      this.$store.state.supportsWebP
    );
  }
  // サブスクパッケージCMSリポジトリ
  get subscriptionPackageCmsRepository() {
    return new SubscriptionPackageCmsRepository();
  }
  // ログイン状態
  get isAuthenticated() {
    return this.$auth.isAuthenticated && !this.$auth.loading;
  }
  // 登録中のサブスクパッケージID配列
  get subscribedPackageIds() {
    return this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY]
      ? getSubscribedPackageIds(this.$auth.user[INFO_SUBSCRIPTION_CLAIMKEY])
      : [];
  }
  // 登録中のサブスクパッケージ一覧表示可否
  get isSubscribedPackageListVisible() {
    return (
      this.isAuthenticated &&
      (this.eventItem?.allowedEntrySubscriptionPackages?.length > 0 ||
        this.eventItem?.discountSubscriptionPackages?.length > 0)
    );
  }

  async created() {
    await this.initialize();
  }
  /**
   * 初期ロード時に発火
   * ・ONE API・microCMS APIからデータをフェッチ
   * ・受け取ったデータに対してバリデーションを実施
   *
   * イベント申込内容確認画面の「戻る」を通じて遷移した場合、
   * Propsにイベント情報・入力内容が含まれているため、イベントデータはフェッチしない。
   */
  async initialize() {
    // クエリー引数を取得
    this.queryEventId = this.$route.params.id;
    // クエリパラメータが存在しない場合は、処理終了
    if (!this.queryEventId) {
      return;
    }
    // Propsでデータを受け取っていない場合はデータをフェッチ
    if (!this.propEventItem) {
      // CMSから各種情報を取得
      await this.fetchCmsEventItem();
      // イベント取得
      await this.getEventInfo();
      // 受け取っている場合はデータをパースする
    } else {
      this.eventItem = EventItem.new(
        JSON.parse(this.propEventItem) as EventItem
      );
      this.requestBody = JSON.parse(this.propRequestBody) as ReqPostEventEntry;
    }
    // サブスク情報取得
    await this.getSubscriptionPackageInfo();

    // 申込期間チェック
    this.validateRegisterPeriod();
    // イベント申込可否チェック
    this.setAllowedEntryFlg();
    // 申込料金チェック
    this.setEventFeeClassCode();
    // 各種初期値を設定
    this.setInitFlgValue();
    // 本関数の最後に、初期化完了フラグをtrueにセットする
    this.isInitialized = true;
  }

  // microCMSからイベント情報を取得する
  async fetchCmsEventItem() {
    const eventItem = await this.eventCmsRepository.select(this.queryEventId);
    // 取得できた場合
    if (eventItem) {
      this.eventItem = eventItem;
      // 取得できなかった場合
    } else {
      // '情報取得に失敗しました。時間を空けて、再度お試しください。'
      this.errorMsg = this.$msg.get('2000054');
    }
  }

  // microCMSからサブスクパッケージ情報を取得する
  async getSubscriptionPackageInfo() {
    await this.subscriptionPackageCmsRepository
      .get()
      .then(res => {
        if (res) {
          this.subscriptionPackages = res;
        }
      })
      .catch(() => {
        this.errorMsg = this.$msg.get('2000054');
      });
  }
  // 指定したIDのサブスクパッケージ名取得
  getSubscriptionPackageName(id: string) {
    const subscriptionPackage = this.subscriptionPackages.find(
      s => s.id === id
    );
    return subscriptionPackage?.packageName || '';
  }

  // イベント在庫取得API呼ぶ
  async getEventInfo() {
    await this.eventRepo
      .getEvent(this.queryEventId)
      .then(res => {
        // 在庫数をセット
        for (const resGetEvent of res) {
          for (const eventOption of this.eventItem.eventOptions) {
            if (eventOption.eventOptionID === resGetEvent.event_option_id) {
              eventOption.remainSeat = resGetEvent.remain_seats;
              break;
            }
          }
        }
      })
      .catch((errCode: number) => {
        this.handleGetEventPriceErr(errCode);
      });
  }

  // 各種フラグの初期値を設定
  setInitFlgValue() {
    // ボタンの非活性を設定(申込可能フラグがfalseかつログイン中 または全てのイベント内選択肢の残席数が0、またはメッセージが空ではない場合)
    this.entryButtonDisabled =
      (!this.isAllowedEntry && this.$auth.isAuthenticated) ||
      this.eventItem.isNoSeat ||
      this.errorMsg !== '';
    // 全チェックボックス・備考非活性(申込可能フラグがfalse またはイベント全体が申込可能期間外 または全てのイベント内選択肢の残席数が0 またはエラーメッセージがあるとき)
    this.inputDisabled =
      !this.isAllowedEntry ||
      !this.registerPeriodDuringFlg ||
      this.eventItem.isNoSeat ||
      this.errorMsg !== '';
    // 選択肢ごとのエラーメッセージの設定・活性非活性を制御
    this.setCheckEventOptionEnable();
  }

  /**
   * イベント申込可否フラグをセット
   */
  setAllowedEntryFlg() {
    // 申込可能パッケージが設定されていない場合は、イベントに申込可能
    if (this.eventItem.allowedEntrySubscriptionPackages.length === 0) {
      this.isAllowedEntry = true;
    } else {
      // 申込可能パッケージIDに、登録中のパッケージIDが含まれていれば、イベントに申込可能
      this.isAllowedEntry = utils.hasSameElement(
        this.eventItem.allowedEntrySubscriptionPackages.map(s => s.id),
        this.subscribedPackageIds
      );
    }
    // 申込不可の場合はエラーメッセージを設定（既にエラーメッセージが設定されている場合は上書きしない）
    if (!this.isAllowedEntry && this.errorMsg === '') {
      this.errorMsg = this.$msg.get('2000094');
    }
  }

  /**
   * 申込料金チェック
   */
  setEventFeeClassCode() {
    // 特別料金適用パッケージが設定されていない場合は、通常料金を適用
    if (this.eventItem.discountSubscriptionPackages.length === 0) {
      this.eventFeeClassCode = this.$cls.EVENT_FEE_CLS.GENERAL.CD;
    } else {
      // 特別料金適用パッケージIDに、登録中のパッケージIDが含まれていれば、特別料金を適用
      this.eventFeeClassCode = utils.hasSameElement(
        this.eventItem.discountSubscriptionPackages.map(s => s.id),
        this.subscribedPackageIds
      )
        ? this.$cls.EVENT_FEE_CLS.DISCOUNT.CD
        : this.$cls.EVENT_FEE_CLS.GENERAL.CD;
    }
  }

  /**
   * イベント内選択肢ごとの活性状態を設定
   */
  setCheckEventOptionEnable() {
    const now = new Date();
    this.eventItem.eventOptions.forEach(eventOption => {
      // 優先度1: 申込可能期間の確認
      if (new Date(eventOption.entryStartDt) > now) {
        eventOption.errorMsg = this.$msg.get('2000081');
        eventOption.inputDisabled = true;
        return;
      }
      if (new Date(eventOption.entryEndDt) < now) {
        eventOption.errorMsg = this.$msg.get('2000083');
        eventOption.inputDisabled = true;
        return;
      }
      // 優先度2: 在庫数の確認
      if (eventOption.remainSeat === 0) {
        eventOption.errorMsg = this.$msg.get('2000082');
        eventOption.inputDisabled = true;
        return;
      }
    });
  }

  // 申込ボタンクリック
  async handleButtonClick() {
    this.errorMsg = '';
    // ログインしていない場合、ログイン画面へ遷移させる
    if (!this.isAuthenticated) {
      // イベント詳細画面から遷移したことをLocalStorageに保存。サインアップ時のボタン出し分けに利用。
      localStorage.setItem(this.eventDetailKey, 'true');
      // イベントIDをlocalStorageに保存しておく。
      localStorage.setItem(this.eventIdKey, this.queryEventId);
      await this.$auth.loginWithRedirect({
        appState: { targetUrl: `/events/` + this.queryEventId }
      });
      return;
    }
    // 選択肢数の確認
    this.validateOptionIsSelected();
    if (this.entryButtonDisabled) {
      return;
    }
    // 選択肢ごとのバリデーションを行い、申込リクエストのボディをセットする
    this.makeRequestBody();
    if (this.entryButtonDisabled) {
      return;
    }
    // 合計金額に応じて動作を制御
    const payAmount = this.requestBody.event_options.reduce((sum, item) => {
      return sum + item.pay_amount;
    }, 0);
    if (payAmount > 0) {
      await this.registerEvent();
    } else {
      await this.registerFreeEventConfirmDialog();
    }
  }

  // リクエストボディの作成
  makeRequestBody() {
    this.requestBody.event_id = this.queryEventId;
    this.requestBody.event_options = [];
    for (const eventOption of this.eventItem.eventOptions) {
      // 選択肢にチェックが入っているか確認
      if (eventOption.isSelected) {
        // ボディに追加する前にバリデーションを実施
        this.validateInputRegisterNumber(eventOption);
        // エラーが存在する場合は終了
        if (eventOption.errorMsg) {
          return;
        }
        // ボディを作成
        const e: EventOption = {
          event_option_id: eventOption.eventOptionID,
          adult_num: Number(eventOption.adultNum),
          pay_amount:
            eventOption.adultPrice(this.eventFeeClassCode) *
              eventOption.adultNum +
            eventOption.childPrice(this.eventFeeClassCode) *
              eventOption.childNum
        };
        // 大人・子ども申し込み区別フラグがtrueの場合、child_numを設定
        if (this.eventItem.entrySeparateAgeFlg) {
          e.child_num = Number(eventOption.childNum);
        }
        this.requestBody.event_options.push(e);
      }
    }
  }

  // エラー状態に応じて申込ボタンの活性状態を制御
  // 共通エラーが存在する場合: 非活性
  // 選択されている選択肢にエラーが存在する場合: 非活性
  setEntryButtonEnable() {
    // 共通エラーが存在するか確認
    if (this.errorMsg) {
      this.entryButtonDisabled = true;
      return;
    }
    // 選択肢ごとにエラーが存在するか確認
    for (const eventOption of this.eventItem.eventOptions) {
      if (eventOption.isSelected && eventOption.errorMsg) {
        this.entryButtonDisabled = true;
        return;
      }
    }
    this.entryButtonDisabled = false;
  }

  /**
   * 初期表示時にイベント全体の申込期間チェック
   */
  validateRegisterPeriod() {
    // 申込開始期間前
    if (this.eventItem.isBeforeEntryDt) {
      this.registerPeriodDuringFlg = false;
      this.infoMsg = this.$msg.get('2000081');
      // 申込開始期間終了後
    } else if (this.eventItem.isAfterEntryDt) {
      this.registerPeriodDuringFlg = false;
      this.infoMsg = this.$msg.get('2000083');
    }
  }

  // 入力した申込人数をチェックする
  validateInputRegisterNumber(e: EventOptionItem) {
    e.errorMsg = ''; // errMsg を空にする
    // 申込数量が全て0または空欄の場合はエラーにする
    if (e.adultNum === 0 && e.childNum === 0) {
      e.errorMsg = this.$msg.get('2000084');
    }
    // 申込数（大人）と申込数（子ども）の合計が1人あたりの申込上限以上の場合
    if (e.adultNum + e.childNum > e.entryLimitPerUser) {
      e.errorMsg = this.$msg.get('2000102', { limit: e.entryLimitPerUser });
    }
    // 申込数量が残席数を超えてるかをチェック
    if (e.remainSeat !== null && e.adultNum + e.childNum > e.remainSeat) {
      e.errorMsg = this.$msg.get('2000086');
    }
    // 合計金額をチェックする (eslintの警告エラーを解消するため、合計金額チェック処理を別メソッドで定義)
    this.validateTotalPrice(e);
    this.setEntryButtonEnable();
  }

  // 合計金額をチェックする
  validateTotalPrice(e: EventOptionItem) {
    // 一般会員、有料イベント、合計金額が0の場合はエラー
    if (
      this.eventFeeClassCode === this.$cls.EVENT_FEE_CLS.GENERAL.CD &&
      e.adultFee + e.childFee > 0 &&
      e.adultFee * e.adultNum + e.childFee * e.childNum === 0
    ) {
      e.errorMsg = this.$msg.get('2000098');
      return;
    }
    // サブスク会員、有料イベント、合計金額が0の場合はエラー
    if (
      this.eventFeeClassCode === this.$cls.EVENT_FEE_CLS.DISCOUNT.CD &&
      e.discountedAdultFee + e.discountedChildFee > 0 &&
      e.discountedAdultFee * e.adultNum + e.discountedChildFee * e.childNum ===
        0
    ) {
      e.errorMsg = this.$msg.get('2000098');
      return;
    }
  }

  // 選択数をチェックする
  validateOptionIsSelected() {
    if (this.eventItem.eventOptions.filter(e => e.isSelected).length) {
      this.errorMsg = '';
    } else {
      this.errorMsg = this.$msg.get('2000114');
    }
    this.setEntryButtonEnable();
  }

  // 無料イベント申込ボタン押下時（確認ダイアログを表示)
  async registerFreeEventConfirmDialog() {
    await this.$refs.RegisterNumberForm.$validator.validateAll().then(res => {
      if (res) {
        // 確認ダイアログを表示
        this.isRegisterFreeEventConfirmDialogOpening = true;
      }
    });
  }

  // 有料イベント申込処理
  async registerEvent() {
    await this.$refs.RegisterNumberForm.$validator.validateAll().then(valid => {
      if (valid) {
        // イベント申込画面へ
        this.$router.push({
          name: 'register-event',
          params: {
            propEventItem: JSON.stringify(this.eventItem),
            propRequestBody: JSON.stringify(this.requestBody),
            eventFeeClassCode: this.eventFeeClassCode
          }
        });
      }
    });
  }
  // 無料イベント申込処理
  async registerFreeEvent() {
    this.entryButtonLoading = true;
    await this.eventRepo
      .postEventEntry(this.requestBody)
      .then(() => {
        this.$router.push({
          name: 'register-event-complete',
          params: {
            propEventItem: JSON.stringify(this.eventItem),
            propRequestBody: JSON.stringify(this.requestBody),
            eventFeeClassCode: this.eventFeeClassCode
          }
        });
      })
      .catch((errCode: number) => {
        this.handleRegisterErr(errCode);
      })
      .finally(() => {
        this.entryButtonLoading = false;
      });
  }

  private handleGetEventPriceErr(errCode: number) {
    switch (errCode) {
      case 40000:
        this.errorMsg = this.$msg.get('2000100');
        break;
      case 50000:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000100');
        break;
    }
  }

  // handleRegisterErrメソッドのcomplexityのエラーを回避できないため、disableする
  // eslint-disable-next-line complexity
  private handleRegisterErr(errCode: number) {
    switch (errCode) {
      case 40000:
        this.errorMsg = this.$msg.get('2000091');
        break;
      case 40001:
      case 40002:
      case 40003:
        this.errorMsg = this.$msg.get('2000092', {
          errorCode: errCode
        });
        break;
      case 40004:
      case 42200:
        this.errorMsg = this.$msg.get('2000074', {
          errorCode: errCode
        });
        break;
      case 42201:
      case 42202:
      case 42203:
        this.errorMsg = this.$msg.get('2000070', {
          errorCode: errCode
        });
        break;
      case 42204:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      case 42207:
        this.errorMsg = this.$msg.get('2000095');
        break;
      case 42208:
        this.errorMsg = this.$msg.get('2000096');
        break;
      case 42209:
        this.errorMsg = this.$msg.get('2000097');
        break;
      case 42211:
        this.errorMsg = this.$msg.get('2000098');
        break;
      case 42212:
        this.errorMsg = this.$msg.get('2000094');
        break;
      case 50000:
        this.errorMsg = this.$msg.get('2000071', {
          errorCode: errCode
        });
        break;
      default:
        this.errorMsg = this.$msg.get('2000091');
        break;
    }
  }
}
