

























































































































































































































































































































































































































import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { EventItem, EventOptionItem } from '@/models/EventItem';
@Component
export default class RegisterNumberForm extends Vue {
  readonly ADULT_NUMBER = '大人数量';
  readonly CHILD_NUMBER = '子ども数量';
  readonly NUMBER = '数量';
  readonly NOTE = '備考';
  readonly NUMBER_OF_ENTRY_PLACEHOLDER = '--';

  selectedEventOptionIndex: number = -1;

  // イベント情報
  @PropSync('eventItem', { required: true })
  syncEventItem!: EventItem;
  // 備考
  @PropSync('note', { required: true })
  syncNote!: string;
  // 全チェックボックス・備考非活性フラグ
  @Prop({ required: true })
  inputDisabled!: boolean;
  // P0233.vue内の初期化完了フラグ
  @Prop({ required: true })
  isInitialized!: boolean;
  // 料金区分
  @Prop({ required: true })
  eventFeeClassCode!: string;

  // 特別料金パッケージリスト表示フラグ
  isDiscountPackageListOpening = false;

  //ユーザがログイン済、且つまとめイベントでない場合 TRUE を返却
  get canEntry() {
    return this.$auth.isAuthenticated && !this.syncEventItem.readOnlyFlg;
  }

  // 申込内容確認画面の「戻る」ボタン（router-link）で遷移した際にも発火する必要があるため、immediateをtrueに設定
  @Watch('isInitialized', { immediate: true })
  setSelectedEventOptionIndex() {
    if (this.isInitialized) {
      // イベント内選択肢が1つかつ、申込可能な場合のみ、選択状態とする
      if (
        this.syncEventItem.eventOptions.length === 1 &&
        !this.inputDisabled &&
        !this.syncEventItem.eventOptions[0].inputDisabled
      ) {
        this.selectedEventOptionIndex = 0;
        this.setIsSelected();
      }
    }
  }

  // 申込数をチェックする
  validateInputRegisterNumber(value: EventOptionItem) {
    this.$emit('validateInputRegisterNumber', value);
  }

  // ラジオボタン選択時のデータセット
  setIsSelected() {
    this.syncEventItem.eventOptions.forEach(
      (eventOption, i) =>
        (eventOption.isSelected = i === this.selectedEventOptionIndex)
    );
    this.$emit('validateOptionIsSelected');
  }

  // 申込数配列（残席数と1人あたりの申込上限のうち、小さい数値を最大入力可能数とする）
  pulldownNumber = (
    remainSeat: number | null,
    entryLimitPerUser: number
  ): number[] => {
    let len = remainSeat
      ? Math.min(remainSeat, entryLimitPerUser)
      : entryLimitPerUser;
    // 0 ~ 最大入力可能数
    return Array.from({ length: len + 1 }, (_, index) => index);
  };
}
